import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import Network from "../network";
import { secondsToTimeString } from "../tools";
import { AnalyticsEntryInterface } from "../model";
import RangeDataGrid from "../components/RangeDataGrid";

interface RejoyceSession {
  start: Date;
  end: Date | null;
}

interface RejoyceSessionLaunchesEntry {
  id: number;
  ip: string;
  license_key: string;
  launch_count: number;
  admin_launch_count: number;
  clinician_launch_count: number;
  patient_launch_count: number;
  anonymous_launch_count: number;
  timeout_count: number;
  sessions: RejoyceSession[];
  avg_session: string;
  max_session: string;
  min_session: string;
}

const columns: GridColDef[] = [
  {
    field: "ip",
    headerName: "IP Address",
    description: "Source IP address of the ReHab platform machine",
    minWidth: 120,
  },
  { field: "license_key", headerName: "License Key", minWidth: 240, flex: 1 },
  { field: "launch_count", headerName: "Launch Count", minWidth: 110 },
  {
    field: "admin_launch_count",
    headerName: "Admin",
    minWidth: 100,
  },
  {
    field: "clinician_launch_count",
    headerName: "Clinician",
    minWidth: 100,
  },
  {
    field: "patient_launch_count",
    headerName: "Patient",
    minWidth: 100,
  },
  {
    field: "anonymous_launch_count",
    headerName: "Anonymous",
    minWidth: 100,
  },
  { field: "timeout_count", headerName: "Timeout", minWidth: 100 },
  { field: "avg_session", headerName: "Average Session", minWidth: 140 },
  { field: "max_session", headerName: "Longest Session", minWidth: 140 },
  { field: "min_session", headerName: "Shortest Session", minWidth: 140 },
];

export default function RejoyceSessionLaunches() {
  const [rows, setRows] = React.useState<RejoyceSessionLaunchesEntry[] | null>(
    null
  );

  const loadRange = async (startDate: Date, endDate: Date) => {
    const resp = await Network.Get(
      `analytics/rejoyce_launches?start_date_iso=${startDate.toISOString()}&end_date_iso=${endDate.toISOString()}`
    );
    if (resp.status === 200) {
      let data = resp.data as AnalyticsEntryInterface[];

      let entries: RejoyceSessionLaunchesEntry[] = [];

      if (data.length > 0) {
        entries.push({
          id: entries.length,
          ip: data[0].ip,
          license_key: data[0].data.license_key,
          launch_count: 0,
          admin_launch_count: 0,
          anonymous_launch_count: 0,
          clinician_launch_count: 0,
          patient_launch_count: 0,
          timeout_count: 0,
          sessions: [],
          avg_session: "",
          min_session: "",
          max_session: "",
        });
      }

      for (let i = 0; i < data.length; ++i) {
        if (
          data[i].data.license_key !== entries[entries.length - 1].license_key
        ) {
          entries.push({
            id: entries.length,
            ip: data[i].ip,
            license_key: data[i].data.license_key,
            launch_count: 0,
            admin_launch_count: 0,
            anonymous_launch_count: 0,
            clinician_launch_count: 0,
            patient_launch_count: 0,
            timeout_count: 0,
            sessions: [],
            avg_session: "",
            min_session: "",
            max_session: "",
          });
        }

        if (data[i].data.data.action === "rejoyce launched") {
          entries[entries.length - 1].launch_count++;
          entries[entries.length - 1].sessions.push({
            start: new Date(data[i].data.timestamp),
            end: null,
          });
        } else if (data[i].data.data.action === "quit rejoyce") {
          if (data[i].data.data.data.timedOut === true)
            entries[entries.length - 1].timeout_count++;

          if(entries[entries.length - 1].sessions.length > 0)
            entries[entries.length - 1].sessions[
              entries[entries.length - 1].sessions.length - 1
            ].end = new Date(data[i].data.timestamp);
        } else if (data[i].data.data.action === "hydrated state") {
          switch (data[i].data.data.data.mode) {
            case "Admin":
              entries[entries.length - 1].admin_launch_count++;
              break;
            case "Clinician":
              entries[entries.length - 1].clinician_launch_count++;
              break;
            case "Patient":
              entries[entries.length - 1].patient_launch_count++;
              break;
            case "Anonymous":
              entries[entries.length - 1].anonymous_launch_count++;
              break;
          }
        }
      }

      for (let i = 0; i < entries.length; ++i) {
        let avgTimeSec = 0;
        let minTimeSec = Number.MAX_VALUE;
        let maxTimeSec = 0;
        for (let j = 0; j < entries[i].sessions.length; ++j) {
          if (entries[i].sessions[j].end !== null) {
            let duractionSec =
              (entries[i].sessions[j].end!.valueOf() -
                entries[i].sessions[j].start.valueOf()) /
              1000;

            if (minTimeSec > duractionSec) minTimeSec = duractionSec;

            if (maxTimeSec < duractionSec) maxTimeSec = duractionSec;

            avgTimeSec += duractionSec;
          }
        }
        if (avgTimeSec !== 0)
          entries[i].avg_session = secondsToTimeString(
            avgTimeSec / entries[i].sessions.length
          );
        else entries[i].avg_session = "-";

        if (minTimeSec !== Number.MAX_VALUE)
          entries[i].min_session = secondsToTimeString(minTimeSec);
        else entries[i].min_session = "-";

        if (maxTimeSec !== 0)
          entries[i].max_session = secondsToTimeString(maxTimeSec);
        else entries[i].max_session = "-";
      }

      setRows(entries);
    }
  };

  return <RangeDataGrid rows={rows} loadRange={loadRange} columns={columns} />;
}
