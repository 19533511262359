import React from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { AnalyticsChunkInterface } from "../model";
import Network from "../network";
import { useWindowSize } from "../tools";

export interface AnalyticsDataGridProps {
  analyticsData: any[] | null;
  formatPageDataOnLoad: any;
  columns: GridColDef[];
}

export default function AnalyticsDataGrid({
  analyticsData,
  formatPageDataOnLoad,
  columns,
  ...props
}: AnalyticsDataGridProps) {
  const [, height] = useWindowSize();
  const hasBeenCalled = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [totalRowCount, setTotalRowCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;
    loadDataPage(page, pageSize);
  };

  React.useEffect(() => {
    constructor();
  });

  const loadDataPage = async (page: number, pageSize: number) => {
    setIsLoading(true);
    const resp = await Network.Get(
      `analytics/chunk?offset=${page * pageSize}&size=${pageSize}`
    );
    if (resp.status === 200) {
      let chunk = resp.data as AnalyticsChunkInterface;
      setTotalRowCount(chunk.total_row_count);
      formatPageDataOnLoad(chunk.rows);
    }
    setIsLoading(false);
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      {analyticsData ? (
        <DataGrid
          loading={isLoading}
          rowCount={totalRowCount}
          paginationMode="server"
          pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          page={page}
          pageSize={pageSize}
          onPageChange={(newPage) => {
            setPage(newPage);
            loadDataPage(newPage, pageSize);
          }}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            loadDataPage(page, newPageSize);
          }}
          rows={analyticsData}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          sx={{
            height: height * 0.75,
            boxShadow: 3,
          }}
        />
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}
