import React from "react";
import Container from "@mui/material/Container";
import ValidatedTextField from "../components/ValidatedTextField";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import { QuestionnaireSimplifiedStateInterface } from "./questionnaireState";
import { formatMoney } from "./PreliviaDataPlot";
import Network from "../network";

const fieldEmptyError = "Please complete this required field.";
const fieldNotNumberError = "Field should contain a number";
const negativeNumberError = "Value cannot be less then zero";
const emailFormatError = "Please enter a valid email address.";

function numberValidator(value: string) {
  if (value.length === 0) {
    return fieldEmptyError;
  }

  let number = Number.parseFloat(value);

  if (Number.isNaN(number)) {
    return fieldNotNumberError;
  }

  if (number < 0) {
    return negativeNumberError;
  }

  return "";
}

function numberRangeValidator(value: number, minIncl: number, maxIncl: number) {
  if (value < minIncl || value > maxIncl) {
    return `Acceptable range is between ${minIncl} and ${maxIncl}. Please enter number in this range.`;
  }

  return "";
}

export function emailValidator(email: string) {
  const emailRegex = /\S+@\S+\.\S+/;
  if (!email) return fieldEmptyError;
  if (!emailRegex.test(email)) return emailFormatError;
  return "";
}

function requiredFieldValidator(value: string) {
  if (value.length === 0) {
    return fieldEmptyError;
  }
  return "";
}

interface PreliviaQuestionnaireSimplifiedProps {
  questionnaireStateObj: QuestionnaireSimplifiedStateInterface | null;
  setQuestionnaireStateObj: (obj: QuestionnaireSimplifiedStateInterface) => void;
  setDataSubmitted: any;
}

export default function PreliviaQuestionnaireSimplified({
  questionnaireStateObj,
  setQuestionnaireStateObj,
  setDataSubmitted,
}: PreliviaQuestionnaireSimplifiedProps) {
  const [name, setName] = React.useState({
    value: questionnaireStateObj ? questionnaireStateObj.name : "",
    error: "",
  });
  const [surname, setSurname] = React.useState({
    value: questionnaireStateObj ? questionnaireStateObj.surname : "",
    error: "",
  });
  const [email, setEmail] = React.useState({
    value: questionnaireStateObj ? questionnaireStateObj.email : "",
    error: "",
  });
  const [mobile, setMobile] = React.useState({
    value: questionnaireStateObj ? questionnaireStateObj.mobile : "",
    error: "",
  });
  const [facilityName, setFacilityName] = React.useState({
    value: questionnaireStateObj ? questionnaireStateObj.facilityName : "",
    error: "",
  });

  const [residents, setResidents] = React.useState({
    value: questionnaireStateObj
      ? questionnaireStateObj.residents.toString()
      : "",
    error: "",
  });
  const [lengthOfStay, setLengthOfStay] = React.useState({
    value: questionnaireStateObj
      ? questionnaireStateObj.lengthOfStay.toString()
      : "28",
    error: "",
  });

  const onSubmit = () => {
    const nameError = requiredFieldValidator(name.value);
    const surnameError = requiredFieldValidator(surname.value);
    const emailError = emailValidator(email.value);
    const facilityNameError = requiredFieldValidator(facilityName.value);
    const residentsError = numberValidator(residents.value);

    let lengthOfStayError = numberValidator(lengthOfStay.value);
    if (lengthOfStayError == "")
      lengthOfStayError = numberRangeValidator(Number.parseInt(lengthOfStay.value), 4, 60);

    if (
      nameError ||
      surnameError ||
      emailError ||
      facilityNameError ||
      residentsError ||
      lengthOfStayError
    ) {
      setName({ ...name, error: nameError });
      setSurname({ ...surname, error: surnameError });
      setEmail({ ...email, error: emailError });
      setFacilityName({ ...facilityName, error: facilityNameError });
      setResidents({ ...residents, error: residentsError });
      setLengthOfStay({ ...lengthOfStay, error: lengthOfStayError });
      return;
    }

    calculate();
  };

  const calculate = () => {
    // ='Customer Interview'!$F$21 | 'HIDDEN - Calculations'!G14
    let numberOfResidents = Number.parseInt(residents.value);
    numberOfResidents = Math.round(numberOfResidents);

    // =IF('Customer Interview'!F49="", 'Customer Interview'!H49, 'Customer Interview'!F49) | 'HIDDEN - Assumptions'!G14
    const PIIncidencePer = 0.036; //Number.parseFloat(pInjIncidence.value) / 100; // Get persentage

    // =IF('Customer Interview'!F24="", 'Customer Interview'!H24, 'Customer Interview'!F24) | 'HIDDEN - Assumptions'!G40
    const avgDays = Number.parseInt(lengthOfStay.value);

    // 'HIDDEN - Calculations'G15
    let avgNumberResFac =
      ((numberOfResidents * 365) / avgDays) * PIIncidencePer;
    avgNumberResFac = Math.round(avgNumberResFac);

    // 'HIDDEN - Assumptions'G19 | 'HIDDEN - Calculations'G17
    const pressureUlcer = 15270;

    // 'HIDDEN - Calculations'G20
    const byIncidence = avgNumberResFac;

    // 'HIDDEN - Calculations'G28
    let estCostsByIncidence = byIncidence * pressureUlcer;
    estCostsByIncidence = Math.round(estCostsByIncidence);

    // OUTPUT
    let result: QuestionnaireSimplifiedStateInterface = {
      name: name.value,
      surname: surname.value,
      email: email.value,
      mobile: mobile.value,
      facilityName: facilityName.value,
      residents: Number.parseInt(residents.value),
      lengthOfStay: Number.parseInt(lengthOfStay.value),
      estCostsByIncidence,
      estCostsByIncidenceFormatted: "$"+formatMoney(estCostsByIncidence)!
    };

    Network.Post("prelivia_cost_calc_api", result);

    setQuestionnaireStateObj(result);
    setDataSubmitted(true);
  };

  return (
    <Container
      component="main"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" style={{ textAlign: "center", color: "#424242" }}>
        Contact Information
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: 20,
        }}
      >
        <ValidatedTextField
          style={{ marginRight: 10 }}
          label="Name *"
          value={name.value}
          onChange={(event: any) => {
            setName({
              value: event.target.value,
              error: "",
            });
          }}
          errorText={name.error}
          infoText={'* Required'}
        />
        <ValidatedTextField
          style={{ marginRight: 10 }}
          label="Surname *"
          value={surname.value}
          onChange={(event: any) => {
            setSurname({
              value: event.target.value,
              error: "",
            });
          }}
          errorText={surname.error}
          infoText={'* Required'}
        />
        <ValidatedTextField
          style={{ marginRight: 10 }}
          label="Email *"
          value={email.value}
          onChange={(event: any) => {
            setEmail({
              value: event.target.value,
              error: "",
            });
          }}
          errorText={email.error}
          infoText={'* Required'}
        />
        <ValidatedTextField
          label="Mobile"
          value={mobile.value}
          onChange={(event: any) => {
            setMobile({
              value: event.target.value,
              error: "",
            });
          }}
          errorText={mobile.error}
        />
      </div>
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginBottom: 10, color: "#424242" }}
      >
        Site Demographics
      </Typography>
      <Typography component="p">
        Please enter the name of your facility:
      </Typography>
      <ValidatedTextField
        label="Name *"
        value={facilityName.value}
        onChange={(event: any) => {
          setFacilityName({
            value: event.target.value,
            error: "",
          });
        }}
        errorText={facilityName.error}
        infoText={'* Required'}
      />
      <Typography component="p">
        1. On average, what is a typical number of patients at your facility (beds X occupancy)?
      </Typography>
      <ValidatedTextField
        label="Patients *"
        value={residents.value}
        onChange={(event: any) => {
          setResidents({
            value: event.target.value,
            error: "",
          });
        }}
        errorText={residents.error}
        infoText={'* Required'}
      />
      <Typography component="p">
        2. What is the average length of stay for residents at your
        facility?
      </Typography>
      <ValidatedTextField
        label="Days *"
        value={lengthOfStay.value}
        onChange={(event: any) => {
          setLengthOfStay({
            value: event.target.value,
            error: "",
          });
        }}
        errorText={lengthOfStay.error}
        infoText={'* Required'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      Please fill in the box with the best estimate for your
                      institution. Alternatively, you can keep the default
                      value, which represents the average across the USA.
                    </Typography>
                    <br />
                    <a
                      style={{ color: "white", fontSize: 16 }}
                      href="https://www.cms.gov/newsroom/fact-sheets/medicare-skilled-nursing-facility-snf-transparency-data-cy2013"
                    >
                      Medicare Skilled Nursing Facility (SNF) Transparency
                      Data (CY2013) | CMS
                    </a>
                  </React.Fragment>
                }
                arrow
                placement="top"
              >
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <Button
        onClick={onSubmit}
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, fontSize: 16, height: 60, width: 120, backgroundColor: "#FBA81A", boxShadow: 0, textTransform: 'none',
        "&.MuiButtonBase-root:hover": {
          backgroundColor: "#FBA81A", 
          boxShadow: 0
        } }}
      >
        Submit
      </Button>
    </Container>
  );
}
