import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColumns,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridEditInputCell,
} from "@mui/x-data-grid";
import { DisplayUserData, UserData } from "../model";
import Network from "../network";
import * as StringValidators from "../stringValidators";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useWindowSize } from "../tools";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = uuidv4();
    setRows((oldRows) => [
      ...oldRows,
      { id, name: "", email: "", uuid: "", isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add User
      </Button>
    </GridToolbarContainer>
  );
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function EditInputCell(props: GridRenderEditCellParams) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEdit(params: GridRenderEditCellParams) {
  return <EditInputCell {...params} />;
}

export default function UserManagementTable({
  userData,
  setUserData,
  ...props
}) {
  const [, height] = useWindowSize();
  const hasBeenCalled = React.useRef(false);

  const [rows, setRows] = React.useState<DisplayUserData[]>([]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const userDD = rows.filter((row) => row.id === id)[0] as DisplayUserData;
    const userData: UserData = {
      email: userDD.email,
      uuid: userDD.uuid,
      name: userDD.name,
    };
    const resp = await Network.Post("users/delete", userData);
    if (resp.status === 200) {
      setRows(rows.filter((row) => row.id !== id));
    } else if (resp.status === 401) {
      alert("Can't delete yourself");
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    let userDD = newRow as DisplayUserData;
    const userData: UserData = {
      email: userDD.email,
      uuid: userDD.uuid,
      name: userDD.name,
    };

    let respData: UserData | null = null;
    const resp = await Network.Post(
      "users/" + (userDD.isNew ? "add" : "edit"),
      userData
    );
    if (resp.status === 200) {
      respData = resp.data;
    }

    if (respData) {
      // If adjusted self
      if (userDD.isNew === false && respData.uuid === userData.uuid)
        setUserData(respData);

      userDD.email = respData.email;
      userDD.name = respData.name;
      userDD.uuid = respData.uuid;
      userDD.isNew = false;
      setRows(rows.map((row) => (row.id === newRow.id ? userDD : row)));
    }
    return userDD;
  };

  const constructor = async () => {
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    const resp = await Network.Get(`users`);
    if (resp.status === 200) {
      let data = resp.data as UserData[];

      let rows: DisplayUserData[] = [];
      data.forEach((entry) => {
        let { email, uuid, name } = entry;
        rows.push({
          isNew: false,
          id: uuid,
          email,
          uuid,
          name,
        });
      });
      setRows(rows);
    }
  };

  React.useEffect(() => {
    constructor();
  });

  const columns: GridColumns = [
    {
      field: "uuid",
      headerName: "User UUID",
      description: "Unique user identifier",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email Address",
      renderEditCell: renderEdit,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        let emailError = StringValidators.emailValidator(params.props.value);
        if (emailError.length === 0) {
          let filtered = rows.filter((row) => row.email === params.props.value);
          if (filtered.length > 0 && filtered[0].id !== params.id)
            emailError = "Email already registered";
        }
        return { ...params.props, error: emailError };
      },
      flex: 1,
      editable: true,
    },
    {
      field: "name",
      headerName: "Full Name",
      renderEditCell: renderEdit,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const nameError = StringValidators.nameValidator(params.props.value);
        return { ...params.props, error: nameError };
      },
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box sx={{ textAlign: "center" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          height: height * 0.75,
          boxShadow: 3,
        }}
      />
    </Box>
  );
}
