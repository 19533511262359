export enum BeliefState {
  eYes,
  eMaybe,
  eNo,
}

export function StringToBeliefState(state: string): BeliefState {
  switch (state) {
    case "Yes":
      return BeliefState.eYes;
    case "No":
      return BeliefState.eNo;
    case "Maybe":
      return BeliefState.eMaybe;
  }
  return BeliefState.eMaybe;
}

export function BeliefStateToString(state: BeliefState): string {
  switch (state) {
    case BeliefState.eYes:
      return "Yes";
    case BeliefState.eNo:
      return "No";
    case BeliefState.eMaybe:
      return "Maybe";
  }
}

export interface BeliefObj {
  text: string;
  state: BeliefState;
}

export interface BeliefInputObj {
  value: string;
  error: string;
}

export const BeliefTexts = [
  "Prelivia could reduce the frequency of sleep interruptions among residents at my facility.",
  "Prelivia would make my nurses' jobs easier.",
  "Prelivia could increase satisfaction among nursing staff at my facility.",
  "Prelivia could increase nursing staff productivity.",
  "Prelivia could improve my residents' quality of life.",
  "Prelivia could improve my residents' well-being.",
  "Prelvia could increase satisfaction among residents' family members.",
  "Prelvia could increase my facility's CMS Five-Star Quality Rating.",
  "Prelvia could increase the occupancy rate at my facility.",
  "Prelivia could improve the reputation of my facility as 'state of theart'.",
];

export const SetUpBeliefInputs = (): BeliefInputObj[] => {
  let inputs: BeliefInputObj[] = [];
  BeliefTexts.forEach((text) => inputs.push({ value: "", error: "" }));
  return inputs;
};
