import * as React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { AnalyticsEntryInterface } from "../model";
import AnalyticsDataGrid from "../components/AnalyticsDataGrid";

interface RawAnalyticsTableEntryInterface {
  id: number;
  ip: string;
  data: string;
  timestamp: string;
}

const columns: GridColDef[] = [
  {
    field: "ip",
    headerName: "IP Address",
    description: "Source IP address of the ReHab platform machine",
    minWidth: 120,
  },
  { field: "data", headerName: "Data", flex: 1, editable: true },
  {
    field: "timestamp",
    headerName: "Timestamp",
    description: "Local timestamp of data received by server",
    width: 280,
  },
];

export default function RawAnalyticsTable() {
  const [analyticsData, setAnalyticsData] = React.useState<
    RawAnalyticsTableEntryInterface[] | null
  >(null);

  const formatPageDataOnLoad = (items: AnalyticsEntryInterface[]) => {
    let counter = 1;
    let tableEntries: RawAnalyticsTableEntryInterface[] = [];
    items.forEach((item) => {
      let entry: RawAnalyticsTableEntryInterface = {
        id: counter++,
        ip: item.ip,
        data: JSON.stringify(item.data),
        timestamp: item.data.timestamp,
      };
      tableEntries.push(entry);
    });
    setAnalyticsData(tableEntries);
  };

  return (
    <AnalyticsDataGrid
      analyticsData={analyticsData}
      formatPageDataOnLoad={formatPageDataOnLoad}
      columns={columns}
    />
  );
}
